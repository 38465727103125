<template>
    <v-list-item :to="{ name: 'M_Button' }" :disabled="disabled">
        <v-list-item-icon>
            <v-icon>mdi-gesture-tap-button</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
            <v-list-item-title>The Button</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'mNavItemButton',
    props: {
        disabled: Boolean,
    }
}
</script>