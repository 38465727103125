import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        serialNumber: {
            lastDigit: 0,
            hasVowel: false,
        },
        batteries: {
            amount: 0,
        },
        ports: {
            parallel: false,
        }
    },
    mutations: {
        setSnLastDigit(state, value) {
            state.serialNumber.lastDigit = value;
        },
        setNBatteries(state, value) {
            state.batteries.amount = value;
        },
        setParallelPort(state, value) {
            state.ports.parallel = value;
        },
    },
    actions: {
    },
    modules: {
    }
})
