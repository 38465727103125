<template>
    <v-container>
        <div class="secondary pa-5">
            <v-img src="@/assets/logo.png" max-width="320px" class="mx-auto"/>
        </div>
        <div>
            <h1>The game</h1>
            <p>
                <a href="https://keeptalkinggame.com/" target="_blank" rel="noopener noreferrer">Keep Talking and Nobody Explodes</a>
                (K.T.A.N.E. for short) is a fun coop game by <em>Steel Crate Games</em> where your goal is to disarm a bomb before it's too late.
                You don't have the instructions and your teammates can't see the bomb.
                <br>
                The game is available on most major platforms.
            </p>

            <h1>The app</h1>
            <h2>About</h2>
            <p>
                This app is an <strong>unofficial</strong> "helper tool", which calculates the instructions based on the information de difuser is giving you.
                It's made to speed up some of the most time consuming modules, so you can beat all the records.
                <br>
                Modules are being added over time in the order of usefulness. 
                Some modules may never be added because it would take you more time to enter the input, than to just figure it out yourself
                (e.g. the keypad, maze, morse). In this case, the original instructions are added so you don't have to swtich apps.
            </p>
            <p>
                All that being said, I warn you the game is more fun and rewarding when played the traditional way.
            </p>

            <h2>Sources &amp; credits</h2>
            <p>
                All information available on this app belongs to the creators of the game, <em>Steel Crate Games</em>.
                <br>
                The results are calculated based on the <a href="https://bombmanual.com" target="_blank" rel="noopener noreferrer">English Manual, version 1.</a>
            </p>

            <h2>Disclaimer</h2>
            <p>
                First, this isn't a standalone app; you will need to buy the game for this to be useful.
                <br>
                Second, I don't make any money from you buying the game or using my app.
                <br>
                Finally, though the code works, I'm only human, so mistakes can happen.
                I do my best to make sure all modules give the correct result, but I can't guarantee it.
                <br>
                If you do encouter an error, deal with it... that's what you get for cheating.
            </p>

            <h2>Tech talk</h2>
            <p>
                The app is built using classic web languages (HTML, CSS, JavaScript), with the addition of the VueJS framework.
            </p>
            Packages used:
            <ul>
                <li>Vuetify</li>
                <li>Vue router</li>
                <li>Vuex</li>
            </ul>
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'Home',

}
</script>
