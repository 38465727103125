import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: {name: 'Home'} },
    {
        path: '/Home',
        name: 'Home',
        component: Home
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
    },
    {
        path: '/m/simple-wires',
        name: 'M_Simple wires',
        component: () => import('../views/SimpleWires.vue'),
    },
    {
        path: '/m/button',
        name: 'M_Button',
        component: () => import('../views/Button.vue'),
    },
    {
        path: '/m/keypads',
        name: 'M_Keypads',
        component: () => import('../views/Keypads.vue'),
    },
    {
        path: '/m/simon-says',
        name: 'M_Simon says',
        component: () => import('../views/SimonSays.vue'),
    },
    {
        path: '/m/whos-on-first',
        name: 'M_Whos on first',
        component: () => import('../views/WhosOnFirst.vue'),
    },
    {
        path: '/m/memory',
        name: 'M_Memory',
        component: () => import('../views/Memory.vue'),
    },
    {
        path: '/m/morse-code',
        name: 'M_Morse code',
        component: () => import('../views/MorseCode.vue'),
    },
    {
        path: '/m/complicated-wires',
        name: 'M_Complicated wires',
        component: () => import('../views/ComplicatedWires.vue'),
    },
    {
        path: '/m/wire-sequence',
        name: 'M_Wire sequence',
        component: () => import('../views/WireSequence.vue'),
    },
    {
        path: '/m/mazes',
        name: 'M_Mazes',
        component: () => import('../views/Mazes.vue'),
    },
    {
        path: '/m/passwords',
        name: 'M_Passwords',
        component: () => import('../views/Passwords.vue'),
    },
    {
        path: '/n/venting-gas',
        name: 'N_Venting gas',
        component: () => import('../views/VentingGas.vue'),
    },
    {
        path: '/n/capacitor-discharge',
        name: 'N_Capacitor discharge',
        component: () => import('../views/CapacitorDischarge.vue'),
    },
    {
        path: '/n/knobs',
        name: 'N_Knobs',
        component: () => import('../views/Knobs.vue'),
    },

    
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
