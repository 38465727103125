<template>
    <v-list-item :to="{ name: 'Settings' }">
        <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'mNavItemSettings',
}
</script>