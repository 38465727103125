<template>
    <v-list-item :to="{ name: 'Home' }">
        <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'mNavItemHome',
}
</script>