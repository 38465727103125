<template>
    <v-menu
    v-model="menu"
    bottom
    offset-y
    :nudge-left="(250 - 58)/2"
    :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <div 
            v-on="on"
            v-bind="attrs"
            style="min-height:1em; min-width: 50px;"
            :class="`countDownTimer ${totalCs < 6000 ? 'yellow' : 'white' }--text`">
                <span :hidden="hideClock">{{ csToTime(totalCs) }}</span>
            </div>
        </template>

        <v-card width="250px">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                        label="Minutes"
                        v-model="form.m"
                        type="number"
                        :rules="[rules.positive]"/>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                        label="Seconds"
                        v-model="form.s"
                        type="number"
                        :rules="[rules.positive, rules.minOverflow]"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="error"
                @click="stopCd">
                    Pause
                </v-btn>

                <v-spacer/>

                <v-btn text color="secondary"
                @click="resetCd">
                    Reset
                </v-btn>

                <v-spacer/>

                <v-btn text color="success"
                @click="startCd">
                    {{ (running && totalCs > 0) ? 'Resume' : 'Start' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
let timer1, timer2;
export default {
    data: () =>({
        running: false,
        form: { m: 5, s: 0 },
        menu: false,
        totalCs: null,
        hideClock: false,
        rules: {
            positive: (v) => v >= 0 || 'Time must be positive',
            minOverflow: (v) => v < 60 || 'Max 59 seconds'
        }
    }),
    methods: {
        formToCs() {
            const m = Number(this.form.m) || 0;
            const s = Number(this.form.s) || 0;

            if (m >= 0 && s >= 0 && s < 60) {
                this.totalCs = (m * 60 + s) * 100;
                return true;
            }
            return false;
        },
        csToTime(centiseconds) {
            const cs = centiseconds % 100;
            const s = Math.floor(centiseconds / 100) % 60;
            const m = Math.floor(centiseconds / (60 * 100));
            let str = '';

            if (m)
                str = `${m > 9 ? m : '0'+m}:${s > 9 ? s : '0'+s}`;
            else
                str = `${s > 9 ? s : '0'+s}.${cs > 9 ? cs : '0'+cs}`;
            
            return str;
        },
        startCd() {
            if (this.running && this.totalCs > 0) {
                if (!timer1)
                    this.countDown();
                this.menu = false;
            }
            else {
                this.stopCd();
                let canStart = this.formToCs();
                if (canStart) {
                    this.countDown();
                    this.running = true;
                    this.menu = false;
                }
            }
        },
        resetCd() {
            this.stopCd();
            this.formToCs();
            this.running = false;
        },
        stopCd() {
            if (timer1) {
                clearTimeout(timer1);
                this.hideClock = false;
                timer1 = null;
            }
        },
        countDown() {
            if (this.totalCs > 60*100) {
                this.totalCs -= 100;
                timer1 = setTimeout(this.countDown, 1000);
            }
            else if (this.totalCs > 0) {
                this.totalCs -= 1;
                timer1 = setTimeout(this.countDown, 10);
            }
            else
                this.flashTimer();
        },
        flashTimer() {
            const flash = () => {
                if (this.totalCs == 0) {
                    this.hideClock = !this.hideClock;
                    timer2 = setTimeout(flash, 500);
                }
            }
            flash();
            
            setTimeout(() => clearTimeout(timer2), 10000);
        },
    },
    created() {
        this.formToCs();
    }
}
</script>

<style scoped>
.countDownTimer {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2em;
    font-weight: bold;
}

</style>