<template>
    <div>
        <v-navigation-drawer
        app
        v-model="drawer"
        dark
        height="100%">
            <v-list-item to="/" >
                <v-list-item-content>
                    <v-img src="@/assets/logo.png" eager/>
                </v-list-item-content>
            </v-list-item>

            <v-divider/>

            <m-nav-menu/>
            
            <template #append>
                <div class="py-4 text-center" style="top-border: solid white 1px">
                    <v-chip class="mx-auto" label>
                        ver. 1.2 | Made with ❤
                    </v-chip>
                </div>
            </template>

        </v-navigation-drawer>

        <v-app-bar app
        color="secondary"
        dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"/>
            <v-spacer />
            <timer/>
            <v-spacer />
            K.T.A.N.E.
        </v-app-bar>
    </div>
</template>

<script>
import mNavMenu from '@/components/nav/navMenu.vue';
import Timer from './timer.vue';

export default {
    name: 'mSkeleton',
    components: { mNavMenu, Timer },
    data: () => ({
        drawer: null
    }),
}
</script>

<style>

</style>