<template>
    <v-list dense nav dark>

        <m-item-home/>
        <m-item-settings/>

        <v-divider/>

        <m-item-simple-wires/>
        <m-item-button/>
        <m-item-keypads/>
        <m-item-simon-says/>
        <m-item-whos-on-first/>
        <m-item-memory/>
        <m-item-morse-code/>
        <m-item-complicated-wires/>
        <m-item-wire-sequence/>
        <m-item-mazes/>
        <m-item-passwords/>

        <v-divider/>

        <m-item-venting-gas/>
        <m-item-capacitor-discharge/>
        <m-item-knobs/>
    </v-list>
</template>

<script>
import mItemHome from './itemHome.vue'
import mItemSettings from './itemSettings.vue'
import mItemSimpleWires from './itemSimpleWires.vue'
import mItemButton from './itemButton.vue'
import mItemKeypads from './itemKeypads.vue'
import mItemSimonSays from './itemSimonSays.vue'
import mItemWhosOnFirst from './itemWhosFirst.vue'
import mItemMemory from './itemMemory.vue'
import mItemMorseCode from './itemMorseCode.vue'
import mItemComplicatedWires from './itemComplicatedWires.vue'
import mItemWireSequence from './itemWireSequence.vue'
import mItemMazes from './itemMazes.vue'
import mItemPasswords from './itemPasswords.vue'
import mItemVentingGas from './itemVentingGas.vue'
import mItemCapacitorDischarge from './itemCapacitorDischarge.vue'
import mItemKnobs from './itemKnobs.vue'

export default {
    name: 'mNavMenu',
    components: {
        mItemHome,
        mItemSettings,
        mItemSimpleWires,
        mItemButton,
        mItemKeypads,
        mItemSimonSays,
        mItemWhosOnFirst,
        mItemMemory,
        mItemMorseCode,
        mItemComplicatedWires,
        mItemWireSequence,
        mItemMazes,
        mItemPasswords,
        mItemVentingGas,
        mItemCapacitorDischarge,
        mItemKnobs,
    }
}
</script>
        