<template>
    <v-app>

        <v-main>
            <m-skeleton />
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import mSkeleton from './components/app/skeleton.vue';

export default {
    name: 'App',
    components: { mSkeleton },
    data: () => ({
        //
    }),
};

// Force https
if (process.env.NODE_ENV === 'production') {
    if (location.protocol !== 'https:') {
        location.replace('https:' + location.href.substring(location.protocol.length));
    }
}
</script>
